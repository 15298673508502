<template>
  <div >
    <notifications></notifications>
    <div class="main-content">
      <!-- <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar> -->
      <b-navbar toggleable="lg" type="dark"  style="position: fixed; width: 100%; padding-bottom: 10px; z-index: 999; top: 0; background-color: #09333A;">
        <b-navbar-brand  to="/dashboard" target="_blank" style="margin-left: 1%;">
          <img src="/img/theme/sembcorp-logo-white.png" alt="">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- <b-link></b-link> -->
            <b-link to="administrator" target="_blank"
              v-if="loginUserData.role_id==3"
              size="sm"
              class=" btn btn-outline-success rounded-pill"
              style="background-color: #71cfb0;">
              <span class="btn-inner--icon mr-1"><i class="ni ni-circle-08"></i></span>
              <span class="btn-inner--text "><strong>Administrator</strong></span>
            </b-link>
            <base-dropdown class="nav-item"
                     tag="li"
                     title-classes="nav-link"
                     title-tag="a"
                     icon="ni ni-bell-55"
                     menu-classes="dropdown-menu-xl dropdown-menu-right py-0 "
                    >

            </base-dropdown>

            
            <b-nav-item-dropdown icon right>
              <!-- Using 'button-content' slot -->
              <template  #button-content>
                <span style="margin-right: 5px; background-color: #71cfb0; color: #007C5A; padding-left: 3px; padding-right: 3px; padding-top: 1px; padding-bottom: 1px; border-radius: 4px;"><i class="far fa-user"></i></span>
                <b>{{ loginUserData.email}}</b>
              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item to="/login">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>


          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      
      <div v-if="route_info[1]=='administrator'">
        <side-admin>
          <template slot="links">
            <sidebar-item-admin
              :link="{
                name: 'Set Up',
                icon: 'ni ni-folder-17 text-grey',
              }">
              <sidebar-item-admin
                :link="{ name: 'Group of Project Engineers', path: 'tracking' }"
              ></sidebar-item-admin>
              <sidebar-item-admin
                :link="{ name: 'Document Tracking Template', path: '/administrator/setup/tracking' }"
              ></sidebar-item-admin>
            </sidebar-item-admin>
            <sidebar-item-admin
              :link="{
                name: 'Audit Logs',
                icon: 'ni ni-folder-17 text-grey',
              }">
              <sidebar-item-admin
                :link="{ name: 'Default', path: 'tracking' }"
              ></sidebar-item-admin>

            </sidebar-item-admin>
            <sidebar-item-admin
              :link="{
                name: 'Template',
                icon: 'ni ni-folder-17 text-grey',
              }">
              <sidebar-item-admin
                :link="{ name: 'Default', path: 'tracking' }"
              ></sidebar-item-admin>

            </sidebar-item-admin>
            <sidebar-item-admin
              :link="{
                name: 'PRM',
                icon: 'ni ni-folder-17 text-grey',
              }">
              <sidebar-item-admin
                :link="{ name: 'Default', path: 'tracking' }"
              ></sidebar-item-admin>

            </sidebar-item-admin>

          </template>
        </side-admin>
        
        <div style="width: 82%; margin-left: 17%;">
          <router-view></router-view>

        </div>
      </div>


      <div v-if="route_info[1]!='administrator'">
        <router-view></router-view>

      </div>

      

      <!-- <div @click="$sidebar.displaySidebar(false)"> -->
        <!-- <fade-transition :duration="200" origin="center top" mode="out-in"> -->
          <!-- your content here -->
           <!-- <b-row v-if="route_info[1]=='administrator'">
            <b-col >
              <ul class="list-group">
                <li class="list-group-item">An item</li>
                <li class="list-group-item">A second item</li>
                <li class="list-group-item">A third item</li>
                <li class="list-group-item">A fourth item</li>
                <li class="list-group-item">And a fifth one</li>
              </ul>

            </b-col>
            <b-col md="10">
              <router-view></router-view>

            </b-col>
           </b-row>
           <b-row v-else>
            <router-view></router-view>

           </b-row> -->
        <!-- </fade-transition>
      </div> -->
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  // import DashboardNavbar from './DashboardNavbar.vue';
  // import ContentFooter from './ContentFooter.vue';
  // import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      // DashboardNavbar,
      // ContentFooter,
      // FadeTransition
    },
    data() {
      return {
        route_info :"",
        loginUserData : JSON.parse(window.localStorage.getItem('loginUser'))
          
      };
    },
    methods: {
      getRutes(){
        // console.log("get routes  : ",this.$route.path);
        let routing = this.$route.path;
        let route = routing.toString().split("/");
        this.route_info = routing.toString().split("/");
        console.log("routing now",this.route_info[1])
      },
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      setBackgroundColor() {
        document.body.classList.add('bg-default-dashboard');
      },
      removeBackgroundColor() {
        document.body.classList.remove('bg-default-moment');
      },
      updateBackground() {
        if (!this.$route.meta.noBodyBackground) {
          this.setBackgroundColor();
        } else {
          this.removeBackgroundColor()
        }
      }
    },
    beforeDestroy() {
      // this.removeBackgroundColor();
    },
    watch: {
      $route: {
        immediate: true,
        handler: function () {
          this.updateBackground()
        }
      }
    },
    mounted() {
      this.getRutes();
      this.initScrollbar();;

    }
  };
</script>
<style >
.bg-default-dashboard {
  background-color: #082A2F;
}

</style>
