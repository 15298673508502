
<template>
  <div >
    <notifications></notifications>
    <div class="main-content">
      <!-- <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar> -->
      <b-navbar toggleable="lg" type="dark"  style="position: fixed; width: 100%; padding-bottom: 10px; z-index: 999; top: 0; background-color: #09333A;">
        <b-navbar-brand  to="/dashboard" style="margin-left: 5%;">

          <img src="/img/theme/sembcorp-logo-white.png" alt="">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- <b-link></b-link> -->
            <b-link to="/administrator/setup/tracking" target="_blank"
                    v-if="loginUserData.role_id==3"
                    size="sm"
                    class=" btn btn-outline-success rounded-pill"
                    style="background-color: #71cfb0;">
              <span class="btn-inner--icon mr-1"><i class="ni ni-circle-08"></i></span>
              <span class="btn-inner--text "><strong>Administrator </strong></span>
              <!-- <span class="btn-inner--text " v-for="item in roles" :key="item.id" v-bind:value="item.id"><strong>{{ loginUserData.role_id==item.id?item.name:"" }}</strong></span> -->
            </b-link>
            <base-dropdown class="nav-item"
                           tag="li"
                           title-classes="nav-link"
                           title-tag="a"
                           icon="ni ni-bell-55"
                           menu-classes="dropdown-menu-xl dropdown-menu-right py-0 ">

            </base-dropdown>

            <b-nav-item-dropdown icon right>

              <!-- Using 'button-content' slot -->
              <template  #button-content>
                <span style="margin-right: 5px; background-color: #71cfb0; color: #007C5A; padding-left: 3px; padding-right: 3px; padding-top: 1px; padding-bottom: 1px; border-radius: 4px;"><i class="far fa-user"></i></span>
                <b>{{ loginUserData.email }}</b>
              </template>
              <b-dropdown-item href="/pages/profile" target="_blank">Profile</b-dropdown-item>



              <b-dropdown-item to="login">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>

          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
    /* eslint-disable no-new */
    import PerfectScrollbar from 'perfect-scrollbar';
    import 'perfect-scrollbar/css/perfect-scrollbar.css';

    function hasElement(className) {
        return document.getElementsByClassName(className).length > 0;
    }

    function initScrollbar(className) {
        if (hasElement(className)) {
            new PerfectScrollbar(`.${className}`);
        } else {
            // try to init it later in case this component is loaded async
            setTimeout(() => {
                initScrollbar(className);
            }, 100);
        }
    }



    import DashboardNavbar from './DashboardNavbar.vue';
    import ContentFooter from './ContentFooter.vue';
    import { FadeTransition } from 'vue2-transitions';
    import { CollapseTransition } from 'vue2-transitions';
    import { BaseNav, Modal } from '@/components';
    import axios from 'axios'
    import {LoginURL, clientId, clientSecret, apiDomain, getHeader} from '@/assets/js/config.js'

    export default {
        components: {
            // DashboardNavbar,
            // ContentFooter,
            FadeTransition,

        },
        data() {
            return {
                model:
                    {
                        project:
                            {
                                project_id: null,
                                project_code: null,
                            },
                        group:
                            {
                                group_id: null,
                                group_code: null,
                            },
                        site:
                            {
                                site_id: null,
                                site_code: null,
                            },
                    },
                defaultDashboard: [],
                projects: [],
                groups: [],
                sites: [],
                modals: {
                    formDashboard: false,
                },
                activeNotifications: false,
                showMenu: false,
                searchModalVisible: false,
                searchQuery: '',
                loginUserData : JSON.parse(window.localStorage.getItem('loginUser'))
            }
        },
        methods: {
            initScrollbar() {
                let isWindows = navigator.platform.startsWith('Win');
                if (isWindows) {
                    initScrollbar('sidenav');
                }
            },
            setBackgroundColor() {
                document.body.classList.add('bg-default-dashboard');
            },
            removeBackgroundColor() {
                document.body.classList.remove('bg-default-moment');
            },
            updateBackground() {
                if (!this.$route.meta.noBodyBackground) {
                    this.setBackgroundColor();
                } else {
                    this.removeBackgroundColor()
                }
            },
            notifyVueformDashboard()
            {
                if ( this.model.project.project_id == 'x' || this.model.project.project_id == ''
                    || this.model.group.group_id == 'x' || this.model.group.group_id == ''
                    || this.model.site.site_id == 'x' || this.model.site.site_id == '')
                {
                    this.$notify({
                        message:
                            '<b>Invalid Input : </b> - Your must select at least ONE Project & ONE Group & ONE Site.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'danger',
                    });
                }
                else
                {
                    this.$notify({
                        message:
                            '<b>Default Dashboard : </b> - New Default Dashbord has been set and Save to your User Profile.',
                        timeout: 10000,
                        icon: 'ni ni-bulb-61',
                        type: 'default',
                    });
                }
                this.modals.formDashboard = false
            },
            clearModal()
            {
                this.modals.formDashboard = false
            },
            notready() {
                this.$notify({
                    message:
                        '<b>Under Developer : </b> - New Task, Site & Populate / Import From Template function is not ready.',
                    timeout: 10000,
                    icon: 'ni ni-bulb-61',
                    type: 'danger',
                });
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            toggleNotificationDropDown() {
                this.activeNotifications = !this.activeNotifications;
            },
            closeDropDown() {
                this.activeNotifications = false;
            },
            toggleSidebar() {
                this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
            },
            hideSidebar() {
                this.$sidebar.displaySidebar(false);
            },
            logoutFunction()
            {
                var loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
                console.log(loginUserData)
                if (loginUserData)
                {
                    localStorage.clear()
                }
                this.$router.push({name: 'Login'})
            },
            goPage(page)
            {
                this.$router.push({path: page })
            },
            beforeDestroy() {
                // this.removeBackgroundColor();
            },
            watch: {
                $route: {
                    immediate: true,
                    handler: function () {
                        this.updateBackground()
                    }
                }
            }
        },
        beforeDestroy() {
            // this.removeBackgroundColor();
        },
        watch: {
            $route: {
                immediate: true,
                handler: function () {
                    this.updateBackground()
                }
            }
        },
        mounted() {
            this.initScrollbar()
        }
    }
</script>
<style >
  .bg-default-dashboard {
    background-color: #082A2F;
  }
</style>
