var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('notifications'),_c('div',{staticClass:"main-content"},[_c('b-navbar',{staticStyle:{"position":"fixed","width":"100%","padding-bottom":"10px","z-index":"999","top":"0","background-color":"#09333A"},attrs:{"toggleable":"lg","type":"dark"}},[_c('b-navbar-brand',{staticStyle:{"margin-left":"1%"},attrs:{"to":"/dashboard","target":"_blank"}},[_c('img',{attrs:{"src":"/img/theme/sembcorp-logo-white.png","alt":""}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.loginUserData.role_id==3)?_c('b-link',{staticClass:" btn btn-outline-success rounded-pill",staticStyle:{"background-color":"#71cfb0"},attrs:{"to":"administrator","target":"_blank","size":"sm"}},[_c('span',{staticClass:"btn-inner--icon mr-1"},[_c('i',{staticClass:"ni ni-circle-08"})]),_c('span',{staticClass:"btn-inner--text "},[_c('strong',[_vm._v("Administrator")])])]):_vm._e(),_c('base-dropdown',{staticClass:"nav-item",attrs:{"tag":"li","title-classes":"nav-link","title-tag":"a","icon":"ni ni-bell-55","menu-classes":"dropdown-menu-xl dropdown-menu-right py-0 "}}),_c('b-nav-item-dropdown',{attrs:{"icon":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticStyle:{"margin-right":"5px","background-color":"#71cfb0","color":"#007C5A","padding-left":"3px","padding-right":"3px","padding-top":"1px","padding-bottom":"1px","border-radius":"4px"}},[_c('i',{staticClass:"far fa-user"})]),_c('b',[_vm._v(_vm._s(_vm.loginUserData.email))])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Profile")]),_c('b-dropdown-item',{attrs:{"to":"/login"}},[_vm._v("Sign Out")])],1)],1)],1)],1),(_vm.route_info[1]=='administrator')?_c('div',[_c('side-admin',[_c('template',{slot:"links"},[_c('sidebar-item-admin',{attrs:{"link":{
              name: 'Set Up',
              icon: 'ni ni-folder-17 text-grey',
            }}},[_c('sidebar-item-admin',{attrs:{"link":{ name: 'Group of Project Engineers', path: 'tracking' }}}),_c('sidebar-item-admin',{attrs:{"link":{ name: 'Document Tracking Template', path: '/administrator/setup/tracking' }}})],1),_c('sidebar-item-admin',{attrs:{"link":{
              name: 'Audit Logs',
              icon: 'ni ni-folder-17 text-grey',
            }}},[_c('sidebar-item-admin',{attrs:{"link":{ name: 'Default', path: 'tracking' }}})],1),_c('sidebar-item-admin',{attrs:{"link":{
              name: 'Template',
              icon: 'ni ni-folder-17 text-grey',
            }}},[_c('sidebar-item-admin',{attrs:{"link":{ name: 'Default', path: 'tracking' }}})],1),_c('sidebar-item-admin',{attrs:{"link":{
              name: 'PRM',
              icon: 'ni ni-folder-17 text-grey',
            }}},[_c('sidebar-item-admin',{attrs:{"link":{ name: 'Default', path: 'tracking' }}})],1)],1)],2),_c('div',{staticStyle:{"width":"82%","margin-left":"17%"}},[_c('router-view')],1)],1):_vm._e(),(_vm.route_info[1]!='administrator')?_c('div',[_c('router-view')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }